interface Window {
  dataLayer: Record<string, any>[];
}

window.dataLayer = window.dataLayer || [];

const getMaybeSelectedStuff = (selectedText: string) => {
  const hasMultipleLinesSelected = selectedText.includes('\n');

  if (!hasMultipleLinesSelected) return {
    selectedEmail: selectedText,
    selectedPhone: selectedText
  }

  const lineSplitSelectedText = selectedText.split('\n');

  const maybeSelectedEmail = lineSplitSelectedText[0];
  const maybeSelectedPhone = lineSplitSelectedText[1];

  return {
    selectedEmail: maybeSelectedEmail,
    selectedPhone: maybeSelectedPhone,
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const introMouseElement = document.querySelector(".intro__mouse");
  const elementSelectorToScrollTo = introMouseElement.getAttribute("href")

  introMouseElement.addEventListener("click", (e) => {
    e.preventDefault();
    const elementToScrollTo = document.querySelector(elementSelectorToScrollTo) as HTMLElement;

    const offsetTop = elementToScrollTo.offsetTop;

    scroll({
      top: offsetTop,
      behavior: "smooth",
    });
  });

  document.addEventListener("copy", function (e) {
    const copiedEmailElement = document.querySelector("#email");
    const copiedNumberElement = document.querySelector("#number");
    const copiedEmail = copiedEmailElement
        ? copiedEmailElement.textContent
        : "";
    const copiedNumber = copiedNumberElement
        ? copiedNumberElement.textContent
        : "";
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    if (selectedText.length > 0) {
      const {selectedEmail, selectedPhone} = getMaybeSelectedStuff(selectedText);

      const isCopiedEmail = selectedEmail.includes(copiedEmail);
      const isPartlyCopiedEmail =
          copiedEmail.includes(selectedEmail);

      if (isCopiedEmail || isPartlyCopiedEmail) {
        window.dataLayer.push({event: "copy_email"});
      }

      const isCopiedNumber = selectedPhone.includes(copiedNumber);
      const isPartlyCopiedNumber =
          copiedNumber.includes(selectedPhone);

      if (isCopiedNumber || isPartlyCopiedNumber) {
        window.dataLayer.push({
          event: "copy_number",
        });
      }

    }
  });
});
